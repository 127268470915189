<template>
  <div
    v-loading="loadingPage"
    class="background-white">
    <div class="language-switcher">
      <span
        :class="[selectedLanguage == 'en' ? 'active' : '', 'bold']"
        @click="selectedLanguage = 'en'">
        <img
          height="12"
          :src="enFlag" />
        <span>EN</span>
      </span>
      <span
        :class="[selectedLanguage == 'de' ? 'active' : '', 'bold']"
        @click="selectedLanguage = 'de'">
        <img
          height="12"
          :src="deFlag" />
        <span>DE</span>
      </span>
    </div>
    <el-row
      type="flex"
      class="auth"
      justify="center">
      <!-- Auth content -->
      <el-col
        :sm="10"
        :xs="24"
        class="flex flex-col justify-center relative auth__content">
        <div
          v-if="$route.name !== 'feedback'"
          class="flex justify-space-between auth__logo-wrapper">
          <div v-if="$route.path === '/auth/forgot_password'">
            <router-link
              class="el-button el-button--small el-button--danger el-button--transparent no-underline"
              to="/auth">
              <i class="el-icon-back pr7" />
              {{ $tr('auth.back_to_login') }}
            </router-link>
          </div>
          <div>
            <img
              src="@/assets/images/dg-logo.svg"
              alt="Dataguard Logo" />
          </div>
        </div>
        <router-view />

        <div class="awards flex items-center justify-center">
          <div class="auth__award-partner mr20" />
          <div
            v-if="selectedLanguage === 'en'"
            class="auth__award-en" />
          <div
            v-else
            class="auth__award-de" />
        </div>

        <el-footer v-if="selectedLanguage === 'en'">
          <a
            href="https://www.dataguard.com/privacy-policy-platform/"
            target="_blank">
            {{ $tr('auth.privacy_policy') }}
          </a>
          <a
            href="https://www.dataguard.com/legal-notice/"
            target="_blank">
            {{ $tr('auth.impressum') }}
          </a>
        </el-footer>
        <el-footer v-else>
          <a
            href="https://www.dataguard.de/datenschutzerklaerung-plattform/"
            target="_blank">
            {{ $tr('auth.privacy_policy') }}
          </a>
          <a
            href="https://www.dataguard.de/impressum/"
            target="_blank">
            {{ $tr('auth.impressum') }}
          </a>
        </el-footer>
      </el-col>
      <!-- Auth cover photo -->
      <el-col
        :xs="0"
        :sm="14">
        <div class="auth__cover" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import logo from '@/assets/images/logo-with-name.svg'
import enFlag from '@/assets/images/language-flags/en.svg'
import deFlag from '@/assets/images/language-flags/de.svg'
import { mapActions, mapState } from 'pinia'
import { useLanguageStore } from '@/stores/languageStore'
import { useAuthStore } from '@/stores/authStore'

export default {
  data() {
    return {
      appName: 'Auth',
      logo,
      enFlag,
      deFlag,
      selectedLanguage: '',
    }
  },
  computed: {
    ...mapState(useAuthStore, ['getLoginWithKeycloak']),
    ...mapState(useLanguageStore, ['lang']),
    loadingPage() {
      return this.getLoginWithKeycloak && this.$route.path === '/'
    },
  },
  watch: {
    selectedLanguage(lang) {
      if (!lang) return
      this.changeLanguage(lang)
    },
  },
  created() {
    this.selectedLanguage = this.lang
  },
  methods: {
    ...mapActions(useLanguageStore, ['setLang']),
    changeLanguage(lang) {
      if (!lang) return
      this.setLang(lang)
      this.$axios.get(`/api/v1/pings?locale=${lang}`, {
        beforeRequest: {
          hidePreloader: true,
        },
      })
    },
  },
}
</script>
