const Login = () => import('@/panels/auth/login.vue')
const ForgotPassword = () => import('@/panels/auth/forgot_password.vue')
const Invitation = () => import('@/panels/auth/invitation.vue')
const Registration = () => import('@/panels/auth/registration.vue')
const NewPassword = () => import('@/panels/auth/new_password.vue')
const ExpiredInvitation = () => import('@/panels/auth/expired_invitation.vue')
const NotfoundInvitation = () => import('@/panels/auth/notfound_invitation.vue')
const CustomerNPSFeedback = () => import('@/panels/auth/CustomerNPSFeedback.vue')

const routes = [
  {
    path: '/auth',
    component: {
      render: createElement => createElement('router-view'),
    },
    children: [
      {
        path: '',
        name: 'login_page',
        component: Login,
      },
      {
        path: 'forgot_password',
        component: ForgotPassword,
      },
      {
        path: 'invitation',
        component: Invitation,
      },
      {
        path: 'registration/:access_token',
        component: Registration,
      },
      {
        path: 'new_password',
        component: NewPassword,
      },
      {
        path: 'expired_invitation',
        name: 'expired_invitation',
        component: ExpiredInvitation,
      },
      {
        path: 'notfound_invitation',
        name: 'notfound_invitation',
        component: {
          default: NotfoundInvitation,
        },
      },
    ],
  },
  {
    path: '/feedback/:token',
    name: 'feedback',
    component: CustomerNPSFeedback,
  },
]

export default routes
