import { defineStore } from 'pinia'

const loginWithKeycloak = document
.getElementsByName('login_with_keycloak')[0]
.getAttribute('content')

export const useAuthStore = defineStore('authStore', {
  state: () => ({
    authErrors: {
      errors: [],
      server: false,
      type: 'error',
    },
    loginWithKeycloak,
  }),
  getters: {
    getLoginWithKeycloak(state) {
      return state.loginWithKeycloak === 'true'
    },
  },
  actions: {
    setAuthErrors(errors, server, type) {
      this.authErrors = {
        errors,
        server,
        type: type !== undefined ? type : (type = 'error'),
      }
    },
  },
})
