import Vue from 'vue'
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueRouter from 'vue-router'
import router from '@/routers/auth'
import axios from '@/utils/axiosSetup'
import { isBlank, tr, errorMessage } from '@/utils/utilityMethods'
import { deserialize } from 'jsonapi-deserializer'

import { installElement } from '@/utils/installElement'
import '@/assets/css/common.scss'
import '@/assets/css/auth.scss'

import Auth from '@/panels/auth/auth.vue'
import apiRoutes from '@/api/apiRoutes'
import * as lodashSelectedMethods from '@/utils/lodashSelectedMethods'

import FormWrapper from '@/components/base/FormWrapper.vue'

Vue.component('FormWrapper', FormWrapper)

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
Vue.use(VueRouter)
Vue.prototype.$lodash = lodashSelectedMethods
Vue.prototype.$isBlank = isBlank
Vue.prototype.$axios = axios
Vue.prototype.$apiRoutes = apiRoutes
Vue.prototype.$tr = tr
Vue.prototype.$deserialize = deserialize
Vue.prototype.$errorMessage = errorMessage

installElement()

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    router,
    pinia,
    render: (createEl) => createEl(Auth),
  }).$mount('#app')
})
